.DialogTrigger {
  z-index: 10000;
  margin-top: 55px;
  margin-left: 10px;
  width: fit-content;
  /* width: fit-content; */
  height: 19px;
  border: 1px solid var(--submit-button-border);
  padding: 10px;
  border-radius: var(--number-box-border-radius);
  color: white;
  background-color: var(--submit-button-background);
  :hover {
    background-image: revert;
    background-color: var(--hover-button-color);
    color: white;
  }
  :active {
    background-color: var(--active-button-color);
    color: white;
  }
}

.DialogOverlay {
  background-color: var(--black-a9);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogParagraph {
  min-width: fit-content;
  width: 260px;
  flex: 1;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 15px;
  line-height: 1;
  color: var(--violet-11);
  box-shadow: 0 0 0 1px var(--violet-7);
  height: 35px;
}

.DialogContent {
  background-color: white;
  border-radius: 6px;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.DialogContent:focus {
  outline: none;
}

.DialogTitle {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 500;
  color: var(--mauve-12);
  font-size: 17px;
}

.DialogDescription {
  margin: 10px 0 20px;
  color: var(--mauve-11);
  font-size: 15px;
  line-height: 1.5;
}

.DialogButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px solid var(--blue-11);
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
}

.DialogButton.blue {
  background-color: var(--blue-4);
  color: var(--blue-11);
}
.DialogButton.blue:hover {
  background-color: var(--blue-5);
}
.DialogButton.blue:focus {
  box-shadow: 0 0 0 2px var(--blue-7);
}

.DialogIconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet-11);
  position: absolute;
  top: 10px;
  right: 10px;
}
.DialogIconButton:hover {
  background-color: var(--violet-4);
}
.DialogIconButton:focus {
  box-shadow: 0 0 0 2px var(--violet-7);
}

.DialogFieldset {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}

.DialogLabel {
  font-size: 15px;
  color: var(--violet-11);
  text-align: left;
  /* width: 90px; */
}

.DialogInput {
  width: 100%;
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 15px;
  line-height: 1;
  color: var(--violet-11);
  box-shadow: 0 0 0 1px var(--violet-7);
  height: 35px;
}
.DialogInput:focus {
  box-shadow: 0 0 0 2px var(--violet-8);
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
