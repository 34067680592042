.ConfirmDialogTitle {
  padding-bottom: 10px;
}

.ConfirmDialogContent {
  background-color: white;
  border-radius: 6px;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
  padding-top: 60px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.ConfirmDialogContent:focus {
  outline: none;
}

.Button.red {
  background-color: var(--red-4);
  color: var(--red-11);
}
.Button.red:hover {
  background-color: var(--red-5);
}
.Button.red:focus {
  box-shadow: 0 0 0 2px var(--red-7);
}

.ConfirmIconButton {
  font-family: inherit;
  border-radius: 100%;
  border: 1px solid var(--violet-7);
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet-11);
  position: absolute;
  top: 10px;
  right: 10px;
}
.ConfirmIconButton:hover {
  background-color: var(--violet-4);
}
.ConfirmIconButton:focus {
  box-shadow: 0 0 0 2px var(--violet-7);
}
