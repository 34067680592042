*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body, #root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

.ConfirmDialogTitle {
  padding-bottom: 10px;
}

.ConfirmDialogContent {
  background-color: #fff;
  border-radius: 6px;
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 60px 25px 25px;
  animation: .15s cubic-bezier(.16, 1, .3, 1) contentShow;
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 10px 38px -10px #0e121659, 0 10px 20px -15px #0e121633;
}

.ConfirmDialogContent:focus {
  outline: none;
}

.Button.red {
  background-color: var(--red-4);
  color: var(--red-11);
}

.Button.red:hover {
  background-color: var(--red-5);
}

.Button.red:focus {
  box-shadow: 0 0 0 2px var(--red-7);
}

.ConfirmIconButton {
  border: 1px solid var(--violet-7);
  color: var(--violet-11);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-family: inherit;
  display: inline-flex;
  position: absolute;
  top: 10px;
  right: 10px;
}

.ConfirmIconButton:hover {
  background-color: var(--violet-4);
}

.ConfirmIconButton:focus {
  box-shadow: 0 0 0 2px var(--violet-7);
}

.TooltipContent {
  color: #000;
  -webkit-user-select: none;
  user-select: none;
  will-change: transform, opacity;
  transform-origin: var(--radix-tooltip-content-transform-origin);
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 15px;
  line-height: 1;
  animation-duration: .25s;
  animation-timing-function: cubic-bezier(.16, 1, .3, 1);
  box-shadow: 0 10px 38px -10px #0e121659, 0 10px 20px -15px #0e121633;
}

.TooltipContent[data-state="delayed-open"][data-side="top"] {
  animation-name: slideDownAndFade;
}

.TooltipContent[data-state="delayed-open"][data-side="right"] {
  animation-name: slideLeftAndFade;
}

.TooltipContent[data-state="delayed-open"][data-side="bottom"] {
  animation-name: slideUpAndFade;
}

.TooltipContent[data-state="delayed-open"][data-side="left"] {
  animation-name: slideRightAndFade;
}

.TooltipArrow {
  fill: #fff;
}

.IconButton {
  color: var(--violet-11);
  box-shadow: 0 2px 10px var(--black-a7);
  background-color: #fff;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  font-family: inherit;
  display: inline-flex;
}

.IconButton:hover {
  background-color: var(--violet-3);
}

.IconButton:focus {
  box-shadow: 0 0 0 2px #000;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

:root, .light, .light-theme {
  --mauve-1: #fdfcfd;
  --mauve-2: #faf9fb;
  --mauve-3: #f2eff3;
  --mauve-4: #eae7ec;
  --mauve-5: #e3dfe6;
  --mauve-6: #dbd8e0;
  --mauve-7: #d0cdd7;
  --mauve-8: #bcbac7;
  --mauve-9: #8e8c99;
  --mauve-10: #84828e;
  --mauve-11: #65636d;
  --mauve-12: #211f26;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --mauve-1: #fdfcfd;
      --mauve-2: #faf9fb;
      --mauve-3: #f2eff3;
      --mauve-4: #eae7ec;
      --mauve-5: #e2dfe6;
      --mauve-6: #dbd8e0;
      --mauve-7: #d0cdd7;
      --mauve-8: #bcbac7;
      --mauve-9: #8e8c99;
      --mauve-10: #83818e;
      --mauve-11: #65636d;
      --mauve-12: #211f26;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --mauve-1: color(display-p3 .991 .988 .992);
        --mauve-2: color(display-p3 .98 .976 .984);
        --mauve-3: color(display-p3 .946 .938 .952);
        --mauve-4: color(display-p3 .915 .906 .925);
        --mauve-5: color(display-p3 .886 .876 .901);
        --mauve-6: color(display-p3 .856 .846 .875);
        --mauve-7: color(display-p3 .814 .804 .84);
        --mauve-8: color(display-p3 .735 .728 .777);
        --mauve-9: color(display-p3 .555 .549 .596);
        --mauve-10: color(display-p3 .514 .508 .552);
        --mauve-11: color(display-p3 .395 .388 .424);
        --mauve-12: color(display-p3 .128 .122 .147);
      }
    }
  }
}

:root, .light, .light-theme {
  --violet-1: #fdfcfe;
  --violet-2: #faf8ff;
  --violet-3: #f4f0fe;
  --violet-4: #ebe4ff;
  --violet-5: #e1d9ff;
  --violet-6: #d4cafe;
  --violet-7: #c2b5f5;
  --violet-8: #aa99ec;
  --violet-9: #6e56cf;
  --violet-10: #654dc4;
  --violet-11: #6550b9;
  --violet-12: #2f265f;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --violet-1: #fdfcfe;
      --violet-2: #faf8ff;
      --violet-3: #f4f0fe;
      --violet-4: #ebe5ff;
      --violet-5: #e1d9ff;
      --violet-6: #d4cafe;
      --violet-7: #c2b5f5;
      --violet-8: #aa99ec;
      --violet-9: #6e56cf;
      --violet-10: #654dc4;
      --violet-11: #6550b9;
      --violet-12: #2f265f;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --violet-1: color(display-p3 .991 .988 .995);
        --violet-2: color(display-p3 .978 .974 .998);
        --violet-3: color(display-p3 .953 .943 .993);
        --violet-4: color(display-p3 .916 .897 1);
        --violet-5: color(display-p3 .876 .851 1);
        --violet-6: color(display-p3 .825 .793 .981);
        --violet-7: color(display-p3 .752 .712 .943);
        --violet-8: color(display-p3 .654 .602 .902);
        --violet-9: color(display-p3 .417 .341 .784);
        --violet-10: color(display-p3 .381 .306 .741);
        --violet-11: color(display-p3 .383 .317 .702);
        --violet-12: color(display-p3 .179 .15 .359);
      }
    }
  }
}

:root {
  --black-a1: #0000000d;
  --black-a2: #0000001a;
  --black-a3: #00000026;
  --black-a4: #0003;
  --black-a5: #0000004d;
  --black-a6: #0006;
  --black-a7: #00000080;
  --black-a8: #0009;
  --black-a9: #000000b3;
  --black-a10: #000c;
  --black-a11: #000000e6;
  --black-a12: #000000f2;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root {
      --black-a1: #0000000d;
      --black-a2: #0000001a;
      --black-a3: #00000026;
      --black-a4: #0003;
      --black-a5: #0000004d;
      --black-a6: #0006;
      --black-a7: #00000080;
      --black-a8: #0009;
      --black-a9: #000000b3;
      --black-a10: #000c;
      --black-a11: #000000e6;
      --black-a12: #000000f2;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root {
        --black-a1: color(display-p3 0 0 0 / .05);
        --black-a2: color(display-p3 0 0 0 / .1);
        --black-a3: color(display-p3 0 0 0 / .15);
        --black-a4: color(display-p3 0 0 0 / .2);
        --black-a5: color(display-p3 0 0 0 / .3);
        --black-a6: color(display-p3 0 0 0 / .4);
        --black-a7: color(display-p3 0 0 0 / .5);
        --black-a8: color(display-p3 0 0 0 / .6);
        --black-a9: color(display-p3 0 0 0 / .7);
        --black-a10: color(display-p3 0 0 0 / .8);
        --black-a11: color(display-p3 0 0 0 / .9);
        --black-a12: color(display-p3 0 0 0 / .95);
      }
    }
  }
}

:root, .light, .light-theme {
  --green-1: #fbfefc;
  --green-2: #f4fbf6;
  --green-3: #e6f6eb;
  --green-4: #d6f1df;
  --green-5: #c4e8d1;
  --green-6: #adddc0;
  --green-7: #8eceaa;
  --green-8: #5bb98b;
  --green-9: #30a46c;
  --green-10: #2b9a66;
  --green-11: #218358;
  --green-12: #193b2d;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --green-1: #fbfefc;
      --green-2: #f4fbf6;
      --green-3: #e6f6eb;
      --green-4: #d6f1df;
      --green-5: #c4e8d1;
      --green-6: #adddc0;
      --green-7: #8eceaa;
      --green-8: #5bb88b;
      --green-9: #30a46c;
      --green-10: #2b9a66;
      --green-11: #00804f;
      --green-12: #193b2d;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --green-1: color(display-p3 .986 .996 .989);
        --green-2: color(display-p3 .963 .983 .967);
        --green-3: color(display-p3 .913 .964 .925);
        --green-4: color(display-p3 .859 .94 .879);
        --green-5: color(display-p3 .796 .907 .826);
        --green-6: color(display-p3 .718 .863 .761);
        --green-7: color(display-p3 .61 .801 .675);
        --green-8: color(display-p3 .451 .715 .559);
        --green-9: color(display-p3 .332 .634 .442);
        --green-10: color(display-p3 .308 .595 .417);
        --green-11: color(display-p3 .19 .5 .32);
        --green-12: color(display-p3 .132 .228 .18);
      }
    }
  }
}

:root, .light, .light-theme {
  --red-1: #fffcfc;
  --red-2: #fff7f7;
  --red-3: #feebec;
  --red-4: #ffdbdc;
  --red-5: #ffcdce;
  --red-6: #fdbdbe;
  --red-7: #f4a9aa;
  --red-8: #eb8e90;
  --red-9: #e5484d;
  --red-10: #dc3e42;
  --red-11: #ce2c31;
  --red-12: #641723;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --red-1: #fffcfc;
      --red-2: #fff7f7;
      --red-3: #feebec;
      --red-4: #fdd;
      --red-5: #ffcecf;
      --red-6: #fdbdbe;
      --red-7: #f4a9aa;
      --red-8: #eb8e90;
      --red-9: #e5484d;
      --red-10: #dd3e42;
      --red-11: #ce2c31;
      --red-12: #641723;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --red-1: color(display-p3 .998 .989 .988);
        --red-2: color(display-p3 .995 .971 .971);
        --red-3: color(display-p3 .985 .925 .925);
        --red-4: color(display-p3 .999 .866 .866);
        --red-5: color(display-p3 .984 .812 .811);
        --red-6: color(display-p3 .955 .751 .749);
        --red-7: color(display-p3 .915 .675 .672);
        --red-8: color(display-p3 .872 .575 .572);
        --red-9: color(display-p3 .83 .329 .324);
        --red-10: color(display-p3 .798 .294 .285);
        --red-11: color(display-p3 .744 .234 .222);
        --red-12: color(display-p3 .36 .115 .143);
      }
    }
  }
}

:root, .light, .light-theme {
  --blue-1: #fbfdff;
  --blue-2: #f4faff;
  --blue-3: #e6f4fe;
  --blue-4: #d5efff;
  --blue-5: #c2e5ff;
  --blue-6: #acd8fc;
  --blue-7: #8ec8f6;
  --blue-8: #5eb1ef;
  --blue-9: #0090ff;
  --blue-10: #0588f0;
  --blue-11: #0d74ce;
  --blue-12: #113264;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --blue-1: #fbfdff;
      --blue-2: #f4faff;
      --blue-3: #e6f4fe;
      --blue-4: #d6eeff;
      --blue-5: #c4e5ff;
      --blue-6: #acd8fc;
      --blue-7: #8ec8f6;
      --blue-8: #5eb1ef;
      --blue-9: #0190ff;
      --blue-10: #0687f0;
      --blue-11: #0073d7;
      --blue-12: #113264;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --blue-1: color(display-p3 .986 .992 .999);
        --blue-2: color(display-p3 .96 .979 .998);
        --blue-3: color(display-p3 .912 .956 .991);
        --blue-4: color(display-p3 .853 .932 1);
        --blue-5: color(display-p3 .788 .894 .998);
        --blue-6: color(display-p3 .709 .843 .976);
        --blue-7: color(display-p3 .606 .777 .947);
        --blue-8: color(display-p3 .451 .688 .917);
        --blue-9: color(display-p3 .247 .556 .969);
        --blue-10: color(display-p3 .234 .523 .912);
        --blue-11: color(display-p3 .15 .44 .84);
        --blue-12: color(display-p3 .102 .193 .379);
      }
    }
  }
}

button, fieldset, input {
  all: unset;
}

.AuthDialogOverlay {
  background-color: var(--black-a9);
  animation: .15s cubic-bezier(.16, 1, .3, 1) overlayShow;
  position: fixed;
  inset: 0;
}

.AuthDialogContent {
  background-color: #fff;
  border-radius: 6px;
  width: 90vw;
  max-width: 450px;
  animation: .15s cubic-bezier(.16, 1, .3, 1) contentShow;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 10px 38px -10px #0e121659, 0 10px 20px -15px #0e121633;
}

.AuthDialogTitle {
  color: var(--mauve-12);
  margin: 0;
  font-weight: 500;
}

.TabsRoot {
  box-shadow: 0 2px 10px var(--black-a4);
  flex-direction: column;
  width: 300px;
  display: flex;
}

.TabsList {
  border-bottom: 1px solid var(--mauve-6);
  flex-shrink: 0;
  display: flex;
}

.TabsTrigger {
  color: var(--mauve-11);
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 45px;
  padding: 0 20px;
  font-family: inherit;
  font-size: 15px;
  line-height: 1;
  display: flex;
}

.TabsTrigger:first-child {
  border-top-left-radius: 6px;
}

.TabsTrigger:last-child {
  border-top-right-radius: 6px;
}

.TabsTrigger:hover {
  color: var(--violet-11);
}

.TabsTrigger[data-state="active"] {
  color: var(--violet-11);
  box-shadow: inset 0 -1px, 0 1px;
}

.TabsTrigger:focus {
  position: relative;
}

.TabsContent {
  background-color: #fff;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  outline: none;
  flex-grow: 1;
  padding: 20px;
}

.Text {
  color: var(--mauve-11);
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 1.5;
}

.Fieldset {
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
}

.Label {
  color: var(--violet-12);
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 1;
  display: block;
}

.Input {
  color: var(--violet-11);
  box-shadow: 0 0 0 1px var(--violet-7);
  border-radius: 4px;
  flex: 1 0 auto;
  height: 35px;
  padding: 0 10px;
  font-size: 15px;
  line-height: 1;
}

.Input:focus {
  box-shadow: 0 0 0 2px var(--violet-8);
}

.Button {
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 35px;
  padding: 0 15px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  display: inline-flex;
}

.Button.green {
  background-color: var(--green-4);
  color: var(--green-11);
}

.Button.green:hover {
  background-color: var(--green-5);
}

.Button.green:focus {
  box-shadow: 0 0 0 2px var(--green-7);
}

.DropdownMenuContent, .DropdownMenuSubContent {
  will-change: transform, opacity;
  background-color: #fff;
  border-radius: 6px;
  min-width: 220px;
  padding: 5px;
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.16, 1, .3, 1);
  box-shadow: 0 10px 38px -10px #16171859, 0 10px 20px -15px #16171833;
}

.DropdownMenuContent[data-side="top"], .DropdownMenuSubContent[data-side="top"] {
  animation-name: slideDownAndFade;
}

.DropdownMenuContent[data-side="right"], .DropdownMenuSubContent[data-side="right"] {
  animation-name: slideLeftAndFade;
}

.DropdownMenuContent[data-side="bottom"], .DropdownMenuSubContent[data-side="bottom"] {
  animation-name: slideUpAndFade;
}

.DropdownMenuContent[data-side="left"], .DropdownMenuSubContent[data-side="left"] {
  animation-name: slideRightAndFade;
}

.DropdownMenuItem, .DropdownMenuCheckboxItem, .DropdownMenuRadioItem, .DropdownMenuSubTrigger {
  color: var(--violet-11);
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px;
  outline: none;
  align-items: center;
  height: 25px;
  padding: 0 5px 0 25px;
  font-size: 13px;
  line-height: 1;
  display: flex;
  position: relative;
}

.DropdownMenuContent a {
  color: inherit;
  text-decoration: none;
}

.DropdownMenuSubTrigger[data-state="open"] {
  background-color: var(--violet-4);
  color: var(--violet-11);
}

.DropdownMenuItem[data-disabled], .DropdownMenuCheckboxItem[data-disabled], .DropdownMenuRadioItem[data-disabled], .DropdownMenuSubTrigger[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}

.DropdownMenuItem[data-highlighted], .DropdownMenuCheckboxItem[data-highlighted], .DropdownMenuRadioItem[data-highlighted], .DropdownMenuSubTrigger[data-highlighted] {
  background-color: var(--violet-9);
  color: var(--violet-1);
}

.DropdownMenuLabel {
  color: var(--mauve-11);
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
}

.DropdownMenuSeparator {
  background-color: var(--violet-6);
  height: 1px;
  margin: 5px;
}

.DropdownMenuItemIndicator {
  justify-content: center;
  align-items: center;
  width: 25px;
  display: inline-flex;
  position: absolute;
  left: 0;
}

.DropdownMenuArrow {
  fill: #fff;
}

.RightSlot {
  color: var(--mauve-11);
  margin-left: auto;
  padding-left: 20px;
}

[data-highlighted] > .RightSlot {
  color: #fff;
}

[data-disabled] .RightSlot {
  color: var(--mauve-8);
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

button {
  all: unset;
}

.I4HnWa_selectRoot {
  height: var(--number-box-height);
}

.I4HnWa_SelectTrigger {
  color: var(--number-box-font-color);
  border: 1px solid var(--number-box-border-color);
  background-color: #fff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  min-width: 60px;
  height: 35px;
  margin-left: 10px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  display: inline-flex;
}

.I4HnWa_SelectTrigger:hover {
  background-color: var(--mauve-3);
}

.I4HnWa_SelectTrigger:focus {
  box-shadow: 0 0 0 2px #000;
}

.I4HnWa_SelectTrigger[data-placeholder], .I4HnWa_SelectIcon {
  color: var(--number-box-font-color);
}

.I4HnWa_SelectContent {
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 10px 38px -10px #16171859, 0 10px 20px -15px #16171833;
}

.I4HnWa_SelectViewport {
  padding: 5px;
}

.I4HnWa_SelectItem {
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  font-size: 13px;
  line-height: 1;
  display: flex;
  position: relative;
}

.I4HnWa_SelectItem[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}

.I4HnWa_SelectItem[data-highlighted] {
  background-color: var(--hover-button-color);
  color: #fff;
  outline: none;
}

.I4HnWa_SelectLabel {
  color: var(--mauve-11);
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
}

.I4HnWa_SelectSeparator {
  background-color: var(--violet-6);
  height: 1px;
  margin: 5px;
}

.I4HnWa_SelectItemIndicator {
  justify-content: center;
  align-items: center;
  width: 25px;
  display: inline-flex;
  position: absolute;
  left: 0;
}

.I4HnWa_SelectScrollButton {
  color: var(--violet-11);
  cursor: default;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  height: 25px;
  display: flex;
}

button {
  all: unset;
}

.selectRoot {
  height: var(--number-box-height);
}

.SelectTrigger {
  color: var(--number-box-font-color);
  border: 1px solid var(--number-box-border-color);
  background-color: #fff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  min-width: 60px;
  height: 35px;
  margin-left: 10px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  display: inline-flex;
}

.SelectTrigger:hover {
  background-color: var(--mauve-3);
}

.SelectTrigger:focus {
  box-shadow: 0 0 0 2px #000;
}

.SelectTrigger[data-placeholder], .SelectIcon {
  color: var(--number-box-font-color);
}

.SelectContent {
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 10px 38px -10px #16171859, 0 10px 20px -15px #16171833;
}

.SelectViewport {
  padding: 5px;
}

.SelectItem {
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  font-size: 13px;
  line-height: 1;
  display: flex;
  position: relative;
}

.SelectItem[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}

.SelectItem[data-highlighted] {
  background-color: var(--hover-button-color);
  color: #fff;
  outline: none;
}

.SelectLabel {
  color: var(--mauve-11);
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
}

.SelectSeparator {
  background-color: var(--violet-6);
  height: 1px;
  margin: 5px;
}

.SelectItemIndicator {
  justify-content: center;
  align-items: center;
  width: 25px;
  display: inline-flex;
  position: absolute;
  left: 0;
}

.SelectScrollButton {
  color: var(--violet-11);
  cursor: default;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  height: 25px;
  display: flex;
}

.IagbGq_modeComboBox {
  margin-top: 30px;
  padding-left: 10px;
  display: block;
}

button {
  all: unset;
}

.IagbGq_selectRoot {
  height: var(--number-box-height);
}

.IagbGq_SelectTrigger {
  color: var(--number-box-font-color);
  border: 1px solid var(--number-box-border-color);
  background-color: #fff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  min-width: 60px;
  height: 35px;
  margin-left: 10px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  display: inline-flex;
}

.IagbGq_SelectTrigger:hover {
  background-color: var(--mauve-3);
}

.IagbGq_SelectTrigger:focus {
  box-shadow: 0 0 0 2px #000;
}

.IagbGq_SelectTrigger[data-placeholder], .IagbGq_SelectIcon {
  color: var(--number-box-font-color);
}

.IagbGq_SelectContent {
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 10px 38px -10px #16171859, 0 10px 20px -15px #16171833;
}

.IagbGq_SelectViewport {
  padding: 5px;
}

.IagbGq_SelectItem {
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  font-size: 13px;
  line-height: 1;
  display: flex;
  position: relative;
}

.IagbGq_SelectItem[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}

.IagbGq_SelectItem[data-highlighted] {
  background-color: var(--hover-button-color);
  color: #fff;
  outline: none;
}

.IagbGq_SelectLabel {
  color: var(--mauve-11);
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
}

.IagbGq_SelectSeparator {
  background-color: var(--violet-6);
  height: 1px;
  margin: 5px;
}

.IagbGq_SelectItemIndicator {
  justify-content: center;
  align-items: center;
  width: 25px;
  display: inline-flex;
  position: absolute;
  left: 0;
}

.IagbGq_SelectScrollButton {
  color: var(--violet-11);
  cursor: default;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  height: 25px;
  display: flex;
}

.DialogTrigger {
  z-index: 10000;
  border: 1px solid var(--submit-button-border);
  border-radius: var(--number-box-border-radius);
  color: #fff;
  background-color: var(--submit-button-background);
  width: -moz-fit-content;
  width: fit-content;
  height: 19px;
  margin-top: 55px;
  margin-left: 10px;
  padding: 10px;
}

.DialogTrigger :hover {
  background-image: revert;
  background-color: var(--hover-button-color);
  color: #fff;
}

.DialogTrigger :active {
  background-color: var(--active-button-color);
  color: #fff;
}

.DialogOverlay {
  background-color: var(--black-a9);
  animation: .15s cubic-bezier(.16, 1, .3, 1) overlayShow;
  position: fixed;
  inset: 0;
}

.DialogParagraph {
  color: var(--violet-11);
  box-shadow: 0 0 0 1px var(--violet-7);
  border-radius: 4px;
  flex: 1;
  align-items: center;
  width: 260px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  height: 35px;
  padding: 0 10px;
  font-size: 15px;
  line-height: 1;
  display: inline-flex;
}

.DialogContent {
  background-color: #fff;
  border-radius: 6px;
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
  animation: .15s cubic-bezier(.16, 1, .3, 1) contentShow;
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 10px 38px -10px #0e121659, 0 10px 20px -15px #0e121633;
}

.DialogContent:focus {
  outline: none;
}

.DialogTitle {
  color: var(--mauve-12);
  margin: 0 0 20px;
  font-size: 17px;
  font-weight: 500;
}

.DialogDescription {
  color: var(--mauve-11);
  margin: 10px 0 20px;
  font-size: 15px;
  line-height: 1.5;
}

.DialogButton {
  border: 2px solid var(--blue-11);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 35px;
  padding: 0 15px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  display: inline-flex;
}

.DialogButton.blue {
  background-color: var(--blue-4);
  color: var(--blue-11);
}

.DialogButton.blue:hover {
  background-color: var(--blue-5);
}

.DialogButton.blue:focus {
  box-shadow: 0 0 0 2px var(--blue-7);
}

.DialogIconButton {
  color: var(--violet-11);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-family: inherit;
  display: inline-flex;
  position: absolute;
  top: 10px;
  right: 10px;
}

.DialogIconButton:hover {
  background-color: var(--violet-4);
}

.DialogIconButton:focus {
  box-shadow: 0 0 0 2px var(--violet-7);
}

.DialogFieldset {
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
  display: flex;
}

.DialogLabel {
  color: var(--violet-11);
  text-align: left;
  font-size: 15px;
}

.DialogInput {
  color: var(--violet-11);
  box-shadow: 0 0 0 1px var(--violet-7);
  border-radius: 4px;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35px;
  padding: 0 10px;
  font-size: 15px;
  line-height: 1;
  display: inline-flex;
}

.DialogInput:focus {
  box-shadow: 0 0 0 2px var(--violet-8);
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%)scale(.96);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%)scale(1);
  }
}

.ConfirmDialogTitle {
  padding-bottom: 10px;
}

.ConfirmDialogContent {
  background-color: #fff;
  border-radius: 6px;
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 60px 25px 25px;
  animation: .15s cubic-bezier(.16, 1, .3, 1) contentShow;
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 10px 38px -10px #0e121659, 0 10px 20px -15px #0e121633;
}

.ConfirmDialogContent:focus {
  outline: none;
}

.Button.red {
  background-color: var(--red-4);
  color: var(--red-11);
}

.Button.red:hover {
  background-color: var(--red-5);
}

.Button.red:focus {
  box-shadow: 0 0 0 2px var(--red-7);
}

.ConfirmIconButton {
  border: 1px solid var(--violet-7);
  color: var(--violet-11);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-family: inherit;
  display: inline-flex;
  position: absolute;
  top: 10px;
  right: 10px;
}

.ConfirmIconButton:hover {
  background-color: var(--violet-4);
}

.ConfirmIconButton:focus {
  box-shadow: 0 0 0 2px var(--violet-7);
}

/*# sourceMappingURL=index.62973fa7.css.map */
